<template>
  <div>
    <v-btn-toggle
      mandatory
      fixed
      app
      dense
      group
      name="MeasurementToolbar"
      color="white"
    >
      <v-tooltip :disabled="isShowTooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn
            name="Coordinate"
            dark
            v-on="on"
            @click="enterModeInMeasurement('coordinate')"
          >
            <v-icon>mdi-pin-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Coordinate") }}</span>
      </v-tooltip>
      <v-tooltip :disabled="isShowTooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn
            name="Distance"
            dark
            v-on="on"
            @click="enterModeInMeasurement('distance')"
          >
            <v-icon>mdi-vector-polyline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Distance") }}</span>
      </v-tooltip>
      <v-tooltip :disabled="isShowTooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn
            name="Area"
            dark
            v-on="on"
            @click="enterModeInMeasurement('area')"
          >
            <v-icon>mdi-vector-polygon</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Area") }}</span>
      </v-tooltip>
      <v-spacer />
      <v-btn text color="white" @click="toggleCoordinateDecimalToDegrees"
        >{{ $t(currentMeasurementMode) }} :
      </v-btn>
      <span class="measurementValueStyle" text color="white">{{
        measurementValue
      }}</span>
      <span
        class="measurementValueStyle"
        text
        color="white"
        v-if="currentMeasurementMode === 'coordinate'"
      >
        {{ $t("Mapcode") + ":" }} {{ mapcodeValue }}</span
      >
      <v-spacer />
    </v-btn-toggle>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "measurement",
  computed: {
    ...mapState({
      currentMeasurementMode: state => state.measurement.currentMeasurementMode,
      measurementValue: state => state.measurement.measurementValue,
      mapcodeValue: state => state.measurement.mapCode,
      isShowTooltip: state => !state.setting.isToolTipsOn
    })
  },
  created() {
    console.log("...Measurement panel created.");
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "home/changeUnitSystemState") {
        this.$store.dispatch("measurement/handleMeasurementCalculations");
      }
    });
  },
  methods: {
    toggleCoordinateDecimalToDegrees() {
      if (this.currentMeasurementMode == "coordinate") {
        this.$store.dispatch("measurement/handleCoordinateDecimalToDegrees");
      }
    },
    enterModeInMeasurement(mode) {
      this.$store.dispatch("measurement/handleChangeMeasurementMode", mode);
    }
  }
};
</script>
<style scoped>
.measurementValueStyle {
  padding: 10px;
}
</style>
